import { toast } from "react-hot-toast";
import { firestore } from "./firebase.config";
import {
  collection,
  doc,
  getDocs,
  updateDoc,
  getDoc,
  query,
  where,
  setDoc,
} from "firebase/firestore";
import { setOrders } from "../context/actions/orderActions";
import { setUsers } from "../context/actions/userActions";
import { setSettings } from "../context/actions/settingsActions";
import { ROLE } from "./constants";
// import { setFilterData } from "../context/reducers/filterReducer";

// GET FIRESTORE DATA - users | orders
export const getFirestoreData = async (
  collectionName,
  setLoading,
  dispatch
) => {
  try {
    setLoading(true);
    const querySnapshot = await getDocs(collection(firestore, collectionName));
    var data = [];
    querySnapshot.forEach((doc) => data.push(doc.data()));
    switch (collectionName) {
      case "orders":
        dispatch(setOrders(data));
        break;
      case "users":
        dispatch(setUsers(data));
        break;
      case "app":
        dispatch(setSettings(data[1]));
        break;
      default:
        toast.error("Implementation Failed");
        break;
    }
  } catch (error) {
    console.log(error);
    toast.error(error.message);
  } finally {
    setLoading(false);
  }
};

// UPDATE FIRESTORE DATA - orders ONLY
export const updateFirestoreData = async (
  orderID,
  target,
  data,
  setLoading,
  dispatch,
  setShowEstimateForm
) => {
  try {
    setLoading(true);
    const orderRef = doc(firestore, "orders", orderID);
    switch (target) {
      case "estimate":
        await updateDoc(orderRef, { orderEstimate: data });
        setShowEstimateForm(false);
        toast.success(`Estimate updated to ${data}`);
        break;
      case "status":
        await updateDoc(orderRef, { orderStatus: data });
        //* dispatch(setFilterData({key:'orderStatus', value: data}))
        toast.success(`Order Status updated to ${data}`);
        break;
      case "payment":
        await updateDoc(orderRef, { paymentStatus: data });
        toast.success(`Payment Status updated to ${data}`);
        break;
      default:
        toast.error("Update Failed");
        break;
    }
    console.log("refreshing data");
    getFirestoreData("orders", setLoading, dispatch);
  } catch (error) {
    console.log(error);
    toast.error(error.message);
  } finally {
    setLoading(false);
  }
};

// FILTER ORDER BY DATE
export const filterOrder = (orders, filter) => {
  const today = 24 * 60 * 60 * 1000;
  const filters = {
    All: 0,
    Today: today,
    Yesterday: 2 * today,
    PastWeek: 7 * today,
  };
  var orderFilter = [];
  orders.forEach((order) => {
    const orderDate = order.orderDate.split("/");
    const orderTime = order.orderTime.split(":");
    const orderDateTime = new Date(
      orderDate[2],
      orderDate[1] - 1,
      orderDate[0],
      orderTime[0],
      orderTime[1].slice(0, 2)
    ).getTime();
    const diff = Date.now() - orderDateTime;
    if (diff <= filters[filter]) orderFilter.push(order);
  });
  return orderFilter;
};

// ORDER STATUS FILTER
export const filterOrderStatus = (orders, status) => {
  const orderStatus = orders?.filter((order) =>
    status.includes(order.orderStatus)
  );
  return orderStatus;
};

// ORDER TYPE FILTER
export const filterOrderType = (orders, type) => {
  const orderType = orders?.filter(
    (order) => type === order.orderType //.split(" ").join("")
  );
  return orderType;
};

// PAYMENT STATUS FILTER
export const filterPaymentStatus = (orders, status) => {
  const paymentStatus = orders?.filter((order) =>
    status.includes(order.paymentStatus)
  );
  return paymentStatus;
};

// PAYMENT TYPE FILTER
export const filterPaymentType = (orders, status) => {
  const paymentStatus = orders?.filter((order) =>
    status.includes(order.paymentMethod)
  );
  return paymentStatus;
};

// PAYMENT METHOD FILTER
export const filterPaymentMethod = (orders, method) => {
  const paymentStatus = orders?.filter(
    (order) =>
      order.paymentStatus === "Paid" && method.includes(order.paymentMethod)
  );
  return paymentStatus;
};

// USER ROLE FILTER
export const filterUserRole = (users, role) => {
  const userRole = users.filter((user) => user.roles?.includes(role));
  return userRole;
};

// FILTER ORDERS MADE BY USER
export const filterUserOrders = (orders, userPhone, orderType) => {
  const filteredOrders = orders?.filter(
    (order) => order.userPhone === userPhone && order.orderType === orderType
  );
  return filteredOrders;
};

// UPDATE SETTINGS
export const updateAppSettings = async (target, data, setLoading, dispatch) => {
  try {
    const settingsRef = doc(firestore, "app", "settings");
    switch (target) {
      case "appOFF":
        await updateDoc(settingsRef, { appOFF: data });
        toast.success(`Settings Updated for ${target}`);
        break;
      case "minimumOrder":
        await updateDoc(settingsRef, { minimumOrder: data });
        toast.success(`Settings Updated for ${target}`);
        break;
      case "deliveryCharges":
        await updateDoc(settingsRef, { deliveryCharges: data });
        toast.success(`Settings Updated for ${target}`);
        break;
      case "freeDeliveryLimit":
        await updateDoc(settingsRef, { freeDeliveryLimit: data });
        toast.success(`Settings Updated for ${target}`);
        break;
      case "maxDeliveryDistance":
        await updateDoc(settingsRef, { maxDeliveryDistance: data });
        toast.success(`Settings Updated for ${target}`);
        break;
      case "deliveryDistanceCharges":
        await updateDoc(settingsRef, { deliveryDistanceCharges: data });
        toast.success(`Settings Updated for ${target}`);
        break;
      default:
        toast.error(`Unsupported Target ${target}`);
        break;
    }
    getFirestoreData("app", setLoading, dispatch);
  } catch (error) {
    console.log(error);
    toast.error(error.message);
  }
};

//

const onError = (error) => {
  console.log(error);
  toast.error(error?.message);
};

export const getCurrentUser = async () => {
  try {
    const userRef = doc(
      firestore,
      "users",
      localStorage.getItem("phoneNumber")
    );
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) return userSnap.data();
    console.log("User Does Not Exist");
    toast.error("User Does Not Exist");
  } catch (error) {
    onError(error);
  }
};

export const setNotificationToken = async (userID, notificationToken) => {
  try {
    const userRef = doc(firestore, "users", userID);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      const token = userSnap.data().notificationToken;
      // Check if token exists & Only update unique/new token
      if (token?.User === notificationToken) return;
      return await setDoc(
        userRef,
        {
          notificationToken: { Admin: notificationToken },
        },
        { merge: true }
      );
    }
    console.log("User Does Not Exist");
    toast.error("User Does Not Exist");
  } catch (error) {
    onError(error);
  }
};

export const getTokensByRole = async (role) => {
  try {
    const userRef = collection(firestore, "users");
    const q = query(userRef, where("roles", "array-contains", role));
    const querySnapshot = await getDocs(q);
    var notificationTokens = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const notificationToken = data?.notificationToken;
      if (!notificationToken) return;
      const roleToken = notificationToken[role];
      if (!roleToken) return;
      if (role === ROLE.User || role === ROLE.Admin)
        return notificationTokens.push(roleToken);

      const isOnline = data?.isOnline;
      if (!isOnline) return;
      if (isOnline[role] && notificationToken)
        notificationTokens.push(roleToken);
    });
    return notificationTokens;
  } catch (error) {
    onError(error);
  }
};
