export const ROLE = {
  User: "User",
  DeliveryBoy: "DeliveryBoy",
  Kitchen: "Kitchen",
  Table: "Table",
  Admin: "Admin",
};

export const LINK = {
  User: 'https://dc-user.web.app',
  DeliveryBoy: 'https://dotcom-delivery.web.app',
  Kitchen: 'https://dc-kitchen.web.app',
  Admin: 'https://dotcom-admin.web.app',
  Table: 'https://dotcom-table.web.app',
};

export const ALLOWED_ROLE = ROLE.Admin;

export const USER_ROLES = [
  { name: ROLE.User, icon: "user", quantity: 0 },
  { name: ROLE.DeliveryBoy, icon: "biking-mountain", quantity: 0 },
  { name: ROLE.Kitchen, icon: "hat-chef", quantity: 0 },
  { name: ROLE.Table, icon: "concierge-bell", quantity: 0 },
  { name: ROLE.Admin, icon: "user-gear", quantity: 0 },
];

export const FILTERS = ["All", "Today", "Yesterday", "PastWeek"];

export const ORDER_TYPE = [
  { name: "Dine In", value: "DineIn", icon: "restaurant", quantity: 0 },
  {
    name: "Take Away",
    value: "TakeAway",
    icon: "hand-holding-box",
    quantity: 0,
  },
  {
    name: "Home Delivery",
    value: "HomeDelivery",
    icon: "home-location",
    quantity: 0,
  },
];

export const PAYMENT_TYPE = [
  { name: "PayOnline", icon: "shield-check", quantity: 0 },
  { name: "PayByCash", icon: "money-bills", quantity: 0 },
];

export const STATUS = {
  Pending: {
    name: "Pending",
    color: "bg-yellow text-black",
    icon: "loading",
    quantity: 0,
  },
  Paid: {
    name: "Paid",
    color: "bg-green text-white",
    icon: "shield-check",
    quantity: 0,
  },
  Accepted: {
    name: "Accepted",
    color: "bg-blue text-white",
    icon: "text-check",
    quantity: 0,
  },
  Preparing: {
    name: "Preparing",
    color: "bg-green text-white",
    icon: "grill",
    quantity: 0,
  },
  Prepared: {
    name: "Prepared",
    color: "bg-lightgreen text-black",
    icon: "pot",
    quantity: 0,
  },
  OutForDelivery: {
    name: "OutForDelivery",
    color: "bg-p-orange text-black",
    icon: "biking-mountain",
    quantity: 0,
  },
  Delivered: {
    name: "Delivered",
    color: "bg-green text-white",
    icon: "map-marker-check",
    quantity: 0,
  },
  Completed: {
    name: "Completed",
    color: "bg-green text-white",
    icon: "check-circle",
    quantity: 0,
  },
  Cancelled: {
    name: "Cancelled",
    color: "bg-grey-dark text-white",
    icon: "cross-circle",
    quantity: 0,
  },
  Rejected: {
    name: "Rejected",
    color: "bg-red text-black",
    icon: "cross-circle",
    quantity: 0,
  },
  Returned: {
    name: "Returned",
    color: "bg-purple text-white",
    icon: "undo",
    quantity: 0,
  },
  Refunded: {
    name: "Refunded",
    color: "bg-grey-dark dark:bg-grey-light text-white dark:text-black",
    icon: "undo",
    quantity: 0,
  },
};

const orderStatusStart = [
  STATUS.Pending,
  STATUS.Accepted,
  STATUS.Preparing,
  STATUS.Prepared,
];

const orderStatusEnd = [STATUS.Cancelled, STATUS.Rejected];

const nonDeliveryOrder = [
  ...orderStatusStart,
  STATUS.Completed,
  ...orderStatusEnd,
];

export const ORDER_STATUS_LIST = {
  DineIn: nonDeliveryOrder,
  TakeAway: nonDeliveryOrder,
  HomeDelivery: [
    ...orderStatusStart,
    STATUS.OutForDelivery,
    STATUS.Delivered,
    ...orderStatusEnd,
  ],
};

export const PAYMENT_STATUS = [STATUS.Pending, STATUS.Paid, STATUS.Refunded];

// ? NOTIFICATION FLOW

//* From User
// Pending(Placed) - Admin

//* From ADMIN
// Accepted - User | Kitchen
// Preparing - User
// Prepared - User | DeliveryBoy
// OutForDelivery - User
// Delivered/Completed - User
// Rejected - User
// Paid - User

//* From KITCHEN
// Preparing - User | DeliveryBoy
// Prepared - User | DeliveryBoy

//* From DELIVERYBOY
// OutForDelivery - User | Admin
// Delivered - User | Admin
// Paid - User | Admin
