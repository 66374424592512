import { React, lazy, useLayoutEffect, useState } from "react";
import {
  Route,
  Routes,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";

import { SharedLayout } from "./components/Global";

import { ManageOrders, OrderDetails } from "./pages/Orders";
import { ManageUsers, UserDetails } from "./pages/Users";
import { LoginSharedLayout } from "./components/Login";
import { LoginWithOTP, VerifyOTP } from "./pages/Login";
import { Settings, Delivery, SendNotification } from "./pages/Settings";
import { useSelector } from "react-redux";
import { onMessageListener } from "./utils/fcm";

const KEY = process.env.REACT_APP_2FACTOR_API_KEY;
export const API = `https://2factor.in/API/V1/${KEY}/SMS`;

const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
// const Catalogue = lazy(()=> import("./pages/Catalogue/Catalogue"));

function App() {
  const location = useLocation().pathname;
  const navigate = useNavigate();

  const user = useSelector((state) =>
    state?.users?.find(
      (user) => user.phone === localStorage.getItem("phoneNumber")
    )
  );

  useLayoutEffect(() => {
    if (["/login", "/login/verify"].includes(location)) {
      return;
    } else if (!user?.roles?.includes("Admin")) {
      toast.error("Admin Login Required");
      return navigate("/login");
    }
    onMessageListener();
  }, [location, navigate, user]);

  const [theme, setTheme] = useState(null);

  useLayoutEffect(() => {
    // checks system theme
    if (window.matchMedia("(prefers-color-scheme:dark)").matches) {
      setTheme("dark");
      localStorage.setItem("theme", "dark");
    } else {
      setTheme("light");
      localStorage.setItem("theme", "light");
    }
    // Disable Right Click & Long Press
    document.addEventListener("contextmenu", (event) => {
      event.preventDefault();
    });
  }, []);

  useLayoutEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  return (
    <main className="relative w-screen h-screen flex flex-col bg-grey-light dark:bg-grey-dark font-inter text-grey-dark dark:text-grey-light select-none">
      <Toaster toastOptions={{ duration: 3500 }} />
      <Routes>
        <Route path="/" element={<SharedLayout />}>
          <Route index element={<Dashboard />} />
          <Route
            path="orders"
            element={
              <>
                <Outlet />
              </>
            }
          >
            <Route index element={<ManageOrders />} />
            <Route path="details/:orderID" element={<OrderDetails />} />
          </Route>
          <Route
            path="users"
            element={
              <>
                <Outlet />
              </>
            }
          >
            <Route index element={<ManageUsers />} />
            <Route path="details/:userPhone" element={<UserDetails />} />
          </Route>
          {/* <Route path="catalogue" element={<Catalogue />} /> */}
          <Route path="settings" element={<SharedLayout />}>
            <Route index element={<Settings />} />
            <Route path="delivery" element={<Delivery />} />
            <Route path="send-notification" element={<SendNotification />} />
          </Route>
        </Route>
        <Route path="login" element={<LoginSharedLayout />}>
          <Route index element={<LoginWithOTP />} />
          <Route path="verify" element={<VerifyOTP />} />
        </Route>
      </Routes>
    </main>
  );
}

export default App;
