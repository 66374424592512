export const GET_SETTINGS = "GET_SETTINGS";
export const SET_SETTINGS = "SET_SETTINGS";

export const setSettings = (settings) => {
  return {
    type: SET_SETTINGS,
    payload: settings,
  };
};

export const getSettings = () => {
  return {
    type: GET_SETTINGS,
  };
};
