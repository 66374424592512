import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import OrderReducer from "./orderReducer";
import UserReducer from "./userReducer";
import filterReducer from "./filterReducer";
import settingsReducer from "./settingsReducer";

const rootReducer = combineReducers({
  orders: OrderReducer,
  users: UserReducer,
  filters: filterReducer,
  settings: settingsReducer
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["orders", "users", "filters", "settings"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default persistedReducer;