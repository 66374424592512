import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { BrowserRouter } from "react-router-dom";
import { createStore } from "redux";
import { Provider } from "react-redux";
import persistedReducer from "./context/reducers";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

const store = createStore(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Suspense
            fallback={
              <div className="flex flex-col justify-center items-center w-screen h-screen gap-8 p-4 bg-grey-light dark:bg-grey-dark text-black dark:text-white">
                <img
                  src={require("./assets/images/dc-logo.png")}
                  alt=""
                  className="px-4 pt-6 pb-10 mt-[-8rem] bg-grey-dark rounded-xl shadow-lg"
                />
                <div className="text-xl tracking-wider">LOADING...</div>
              </div>
            }
          >
            <App />
          </Suspense>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
serviceWorkerRegistration.register()