import { GET_SETTINGS, SET_SETTINGS } from "../actions/settingsActions";

const SettingsReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_SETTINGS:
      return action.payload;

    case GET_SETTINGS:
      return state;

    default:
      return state;
  }
};

export default SettingsReducer;
